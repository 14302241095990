// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../../tree/Level.js";
import * as Query from "../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Section from "../../vessel/Section.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AppSchema from "../../AppSchema.js";
import * as LevelType from "../../tree/LevelType.js";
import * as ServerSyncPipeline from "../common/ServerSyncPipeline.js";

function $$do(state) {
  var $$final = state.final;
  var initial = state.initial;
  var vessels = state.vessels;
  var user = state.user;
  var levelTypeKinds = [
    "structural_component",
    "safety_component",
    "sire_chapter",
    "company_form",
    "checklist_part"
  ];
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.catchResolve(Prelude.thenResolve(Query.read((newrecord.sireVettingPeriods = "All", newrecord.levelTypes = LevelType.anyOfKind(levelTypeKinds), newrecord.levels = {
                        TAG: "AnyOf",
                        _0: "vessel_id",
                        _1: vessels
                      }, newrecord.companyForms = {
                        TAG: "AnyOf",
                        _0: "vessel_id",
                        _1: vessels
                      }, newrecord.components = {
                        TAG: "AnyOf",
                        _0: "vessel_id",
                        _1: vessels
                      }, newrecord.sections = {
                        TAG: "AnyOf",
                        _0: "vessel_id",
                        _1: vessels
                      }, newrecord.vessels = {
                        TAG: "In",
                        _0: vessels
                      }, newrecord)), (function (param) {
                    var vessels = param.vessels;
                    Curry._2(Prelude.$$Array.forEach, vessels, (function (extra) {
                            return AppSchema.Vessels.update(initial, extra);
                          }));
                    Curry._2(Prelude.$$Array.forEach, vessels, (function (extra) {
                            return AppSchema.Vessels.update($$final, extra);
                          }));
                    Curry._2(Prelude.$$Array.forEach, param.sections, (function (extra) {
                            return AppSchema.Sections.update($$final, extra);
                          }));
                    Curry._2(Prelude.$$Array.forEach, param.components, (function (extra) {
                            return AppSchema.Components.update($$final, extra);
                          }));
                    Curry._2(Prelude.$$Array.forEach, param.companyForms, (function (extra) {
                            return AppSchema.CompanyForms.update($$final, extra);
                          }));
                    Curry._2(Prelude.$$Array.forEach, param.levels, (function (extra) {
                            return AppSchema.Levels.update($$final, extra);
                          }));
                    Curry._2(Prelude.$$Array.forEach, param.levelTypes, (function (extra) {
                            return AppSchema.LevelTypes.update($$final, extra);
                          }));
                    Curry._2(Prelude.$$Array.forEach, param.sireVettingPeriods, (function (extra) {
                            return AppSchema.SireVettingPeriods.update($$final, extra);
                          }));
                    return {
                            TAG: "Ok",
                            _0: {
                              user: user,
                              vessels: state.vessels,
                              initial: initial,
                              final: $$final
                            }
                          };
                  })), (function (error) {
                return {
                        TAG: "Error",
                        _0: error
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do
};

function $$do$1(state) {
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  newrecord.vessels = AppSchema.Vessels.getActions(state.initial, state.final);
  Query.ActionLogging.info("ServerSyncVesselModules" + ": db actions: ", newrecord);
  return Prelude.PromisedResult.map(Query.Safe.write(newrecord), (function (param) {
                return state;
              }));
}

var UpdateDB = {
  $$do: $$do$1
};

function $$do$2(state) {
  var $$final = state.final;
  Curry._2(Prelude.$$Array.forEach, AppSchema.Vessels.all($$final), (function (vessel) {
          var sectionIds = Section.ids(Section.unarchived(AppSchema.Sections.all($$final)));
          var components = Curry._2(Prelude.$$Array.keep, AppSchema.Components.all($$final), (function (c) {
                  if (Caml_obj.equal(c.vessel_id, vessel.id)) {
                    return Prelude.$$Array.includes(sectionIds, c.section_id);
                  } else {
                    return false;
                  }
                }));
          var hasCompanyForms = Curry._1(Prelude.OptionExported.$$Option.isSome, AppSchema.CompanyForms.all($$final).find(function (f) {
                    return Caml_obj.equal(f.vessel_id, vessel.id);
                  }));
          var levels = Curry._2(Prelude.$$Array.keep, AppSchema.Levels.all($$final), (function (f) {
                  return Caml_obj.equal(f.vessel_id, vessel.id);
                }));
          var levelTypes = AppSchema.LevelTypes.all($$final);
          var hasNonSireLevels = Curry._1(Prelude.OptionExported.$$Option.isSome, levels.find(function (l) {
                    return Level.isKindOf(l, "vessel", levelTypes);
                  }));
          var hasSireLevels = Curry._1(Prelude.OptionExported.$$Option.isSome, levels.find(function (l) {
                    return Level.isKindOf(l, "sire_chapter", levelTypes);
                  }));
          var sireVettingPeriods = AppSchema.SireVettingPeriods.isNotEmpty($$final) || AppSchema.Sire2VettingPeriods.isNotEmpty($$final);
          var modules = {
            checklist: false,
            structural: false,
            safety: false,
            companyForms: hasCompanyForms,
            sire: hasSireLevels,
            sireVettingPeriods: sireVettingPeriods
          };
          var modules$1 = hasNonSireLevels ? Curry._3(Prelude.$$Array.fold, levels, modules, (function (param, level) {
                    return {
                            checklist: param.checklist || Level.isKindOf(level, "checklist_part", levelTypes),
                            structural: param.structural || Level.isKindOf(level, "structural_component", levelTypes),
                            safety: param.safety || Level.isKindOf(level, "safety_component", levelTypes),
                            companyForms: param.companyForms || Level.isKindOf(level, "company_form", levelTypes),
                            sire: hasSireLevels,
                            sireVettingPeriods: sireVettingPeriods
                          };
                  })) : Curry._3(Prelude.$$Array.fold, components, modules, (function (param, component) {
                    return {
                            checklist: param.checklist || component.type === "checklist",
                            structural: param.structural || component.type === "structural",
                            safety: param.safety || component.type === "safety",
                            companyForms: hasCompanyForms,
                            sire: hasSireLevels,
                            sireVettingPeriods: sireVettingPeriods
                          };
                  }));
          var newrecord = Caml_obj.obj_dup(vessel);
          newrecord.modules = modules$1;
          AppSchema.Vessels.update($$final, newrecord);
        }));
  return Promise.resolve({
              TAG: "Ok",
              _0: state
            });
}

var ApplyLocallyKnownState = {
  $$do: $$do$2
};

var ReadServerState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var CollectFiles = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [
  AppSchema.Vessels,
  AppSchema.Sections,
  AppSchema.Components,
  AppSchema.CompanyForms,
  AppSchema.Levels
];

var Views = {
  views: views
};

var CleanUpState = ServerSyncPipeline.MakeStandardCleanup(Views);

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncVesselModules",
      ReadServerState: ReadServerState,
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var $$do$3 = include.$$do;

export {
  FillInitialStatus ,
  UpdateDB ,
  ApplyLocallyKnownState ,
  $$do$3 as $$do,
}
/* include Not a pure module */
