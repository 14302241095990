// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../../tree/Level.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as VesselId from "../../vessel/VesselId.js";
import * as LevelType from "../../tree/LevelType.js";
import * as ListLayout from "../../common/views/ListLayout.js";
import * as Hyphenated from "hyphenated";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as UserProfileCard from "../../accounts/components/UserProfileCard.js";

async function update(vnode) {
  var vesselId = VesselId.Route.param("vesselId");
  var system = State__Memory.Current.get();
  var currentProfile = system.profile;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.levelTypes = LevelType.ofKind("sire"), newrecord));
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord$1.levels = {
          TAG: "And",
          _0: Level.ofVessel(vesselId),
          _1: {
            TAG: "AnyOf",
            _0: "kind",
            _1: match.levelTypes.map(function (param) {
                  return param.id;
                })
          }
        }, newrecord$1));
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              vesselId: vesselId,
              selectedLevel: Prelude.default(Prelude.$$Array.first(match$1.levels), Level.Defaults.$$null),
              currentProfile: currentProfile
            });
}

function make(param) {
  return Mithril.view(Mithril.oninitAsync(Mithril.component("NotReady"), update), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return React.jsx(ListLayout.make, {
                              children: Mithril.empty,
                              title: "SIRE 2.0",
                              showBackButton: true,
                              showSettings: false
                            });
                }
                var currentProfile = match.currentProfile;
                var selectedLevel = match.selectedLevel;
                var vesselId = match.vesselId;
                return React.jsxs(ListLayout.make, {
                            children: [
                              ReactDOM.jsxs("p", {
                                    children: [
                                      ReactDOM.jsx("strong", {
                                            children: "Welcome to the Kaiko SIRE 2.0 module!"
                                          }),
                                      ReactDOM.jsx("div", {
                                            className: "divider blank"
                                          }),
                                      Hyphenated.hyphenated("This powerful tool has been specially designed to help your team prepare for the SIRE 2.0 vetting exercise. Our self-assessment feature allows vessels to get ready for the inspection conducted by regulators, by providing a library that mirrors the SIRE 2.0 questions chapter by chapter. It also allows different officers to conduct self-assessments ahead of the OCIMF inspection.")
                                    ],
                                    className: "text-small m-2"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider blank"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: currentProfile !== undefined ? [
                                        ReactDOM.jsx("p", {
                                              children: "Your current profile is:",
                                              className: "m-0 text-bold"
                                            }),
                                        React.jsx(UserProfileCard.make, {
                                              profile: currentProfile
                                            }),
                                        React.jsx(Mithril.Link.make, {
                                              href: "/user-profiles",
                                              className: Mithril.$$class([[
                                                      "btn btn-sm mb-2",
                                                      true
                                                    ]], undefined),
                                              children: "Switch profile"
                                            })
                                      ] : [
                                        ReactDOM.jsx("p", {
                                              children: "You need a profile in order to continue.",
                                              className: "m-0 text-bold text-warning"
                                            }),
                                        React.jsx(Mithril.Link.make, {
                                              href: "/user-profiles",
                                              className: Mithril.$$class([[
                                                      "btn btn-primary btn-sm mb-2",
                                                      true
                                                    ]], undefined),
                                              children: "Manage profiles"
                                            })
                                      ],
                                    className: "flex-centered flex-direction-column p-2 mb-2"
                                  })
                            ],
                            title: "SIRE 2.0",
                            showBackButton: true,
                            bottom: Caml_option.some(ReactDOM.jsx("button", {
                                      children: "Continue",
                                      className: Mithril.$$class([
                                            [
                                              "btn btn-primary btn-block btn-lg ",
                                              true
                                            ],
                                            [
                                              "disabled",
                                              Curry._1(Prelude.OptionExported.$$Option.isNone, currentProfile)
                                            ]
                                          ], undefined),
                                      onClick: (function (param) {
                                          Mithril$1.route.set(URLs.vesselLevelByKind(vesselId, selectedLevel.id, "sire", undefined, undefined, undefined));
                                        })
                                    }))
                          });
              }));
}

export {
  update ,
  make ,
}
/* URLs Not a pure module */
